@font-face {
  font-family: "jazeera";
  src: url("../public/fonts/jazeera.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "jazeera" ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e7e7e7;

}

* {
  font-family: "jazeera" ;
}

code {
  font-family: "jazeera" ;
}
 