table {
    border-collapse:collapse;
}

.gray {background-color: #7f7f7f;color:white}
.lgray {background-color: #a5a5a5;color:white}
.gold {background-color: #938953;color:white}
.cyan {background-color: #92cddc;}
.light {background-color: #dbe5f1;}
.red {background-color: #e5b8b7;}
.orange {background-color: #fbd4b4;}
.yellow {background-color: #eaf1dd;}
.lorange {background-color: #ddd9c3;}
.lyellow {background-color: #d6e3bc;}
.lpurple { background-color:#e5dfec;}